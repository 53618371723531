import { environment } from '../environments/environment';

export class Constants {
  private static V1 = '/v1/';
  private static idioma = '';
  public static SCHEDULER_BASE_URL = environment.hostScheduler;
  public static BASE_URL = environment.host;
  public static BASE_URL_DOMAIN = environment.domain;
  public static BASE_URL_DOMAIN_MEDIA = environment.domainMedia;
  public static BASE_IMG_URL = environment.hostS3;
  public static BASE_URL_MEDIAS_ENDPOINT = environment.hostMedias;
  public static AWS_REGION = environment.awsRegion;
  public static AWS_ENDPOINT = environment.awsEndpoint;
  public static AWS_S3_ENDPOINT = environment.hostS3;
  public static TAG_ENDPOINT = environment.hostTag;
  public static MOBILE_DEVICES_ENDPOINT = environment.hostMobileDevices;
  public static FEEDBACKS_ENDPOINT = environment.feedbackLambdaUrl;
  public static KNOWLEDGEBASE_ENDPOINT = environment.hostKnowledgeBase;
  public static CHAT_ENDPOINT = environment.LLMchat.aws_appsync_graphqlEndpoint;
  public static CHAT_API_ID = environment.LLMchat.apiId;
  public static CDN_S3_MEDIAS = 'https://medias.robovisorusa.com/';
  public static AWS_S3_DEVICE = 'device';
  public static AWS_S3_MEDIA = 'media';
  public static AWS_S3_COMPANY = 'company';
  public static AWS_S3_SCREENSHOT = 'screenshot';
  public static AWS_IOT_CORE_ENDPOINT = `wss://${environment.awsEndpoint}.iot.${environment.awsRegion}.amazonaws.com/mqtt`;
  public static COUNT_DELAY_MQTT_CHECK = 8;
  public static WIFI_STRENGTH_LOW = 60;
  public static WIFI_STRENGTH_MEDIUM = 70;
  public static WIFI_STRENGTH_HIGH = 80;
  static WS_ENDPOINT = environment.wssEndpoint;
  static WS_RECONNECT_INTERVAL = 60000;
  static FREE = '/free';
  public static AWS_TURN_CREDENTIALS = environment.awsTurnCredentials;
  public static STUN_URL = 'stun:turnr.robovisorusa.com:3478';
  public static TURN_URL = 'turn:turnr.robovisorusa.com';

  static AUTH = 'auth';
  static AUTH_COMPANY = 'auth_company';
  static AUTH_GUEST = 'auth_guest';

  static UPLOADER = 'uploader';
  static MESSAGE = 'message';
  static UTIL = 'util';
  static USER = 'credential';

  static LOGIN = Constants.endPoint(Constants.AUTH);
  static LOGIN_COMPANY = Constants.endPoint(Constants.AUTH_COMPANY);
  static LOGIN_GUEST = Constants.SCHEDULER_BASE_URL + Constants.AUTH_GUEST;
  static FORGOT_PASSWORD = Constants.endPoint('forgot_password');
  static REWRITE_PASSWORD = Constants.endPoint('rewrite_password');
  static USER_REWRITE_PASSWORD = Constants.endPoint('users/rewrite_password');
  static DELETE_ACCOUNT_NOTIFICATION = Constants.endPoint(
    'notifications/delete_account'
  );
  static CHANGE_MEDIA = 'CHANGE_MEDIA';
  static CHANGE_MEDIA_FEED = 'CHANGE_MEDIA_FEED';
  static CHANGE_EYES_FEED = 'CHANGE_EYES_FEED';
  static CHANGE_EYES = 'CHANGE_EYES';
  static CHANGE_SURVEY_FORM = 'CHANGE_SURVEY_FORM';
  static PEOPLE_COUNT = 'PEOPLE_COUNT';
  static CHANGE_CAROUSEL = 'CHANGE_CAROUSEL';
  static CHANGE_CAROUSEL_FEED = 'CHANGE_CAROUSEL_FEED';
  static CHANGE_SURVEY_FORM_FEED = 'CHANGE_SURVEY_FORM_FEED';
  static SCREEN_SHARING_FEED = 'SCREEN_SHARING_FEED';
  static SCREEN_SHARING = 'SCREEN_SHARING';
  static HIGH_RESOLUTION_SCREENSHOT = 'HIGH_RESOLUTION_SCREENSHOT';
  static HIGH_RESOLUTION_SCREENSHOT_FEED = 'HIGH_RESOLUTION_SCREENSHOT_FEED';
  static CHECK_DEVICE_MQTT_CONNECTION = 'CHECK_DEVICE_MQTT_CONNECTION';
  static SPEAK = 'SPEAK';
  static SPEAK_FEED = 'SPEAK_FEED';
  static BROWSER_REFRESH = 'BROWSER_REFRESH';
  static BROWSER_VERSION = 'BROWSER_VERSION';
  static LASER = 'laser';
  static VOLUME = 'volume';
  static PING = 'ping';
  static RESET = 'reset';
  static HARDWARE = 'HARDWARE';
  static NEW_PARTICIPANT = 'new_participant';

  static VERSION = `${environment.baseVersion}--1.28.0`;
  static IMAGE_PATH = '';
  static IMAGE_PLACEHOLDER = 'assets/images/pattern1.png';

  static endPoint(modulePath: string, apiPath: string = null): string {
    if (
      modulePath === 'medias' ||
      modulePath === 'mediaTypes' ||
      modulePath === 'screenShots' ||
      modulePath === 'files'
    ) {
      return Constants.BASE_URL_MEDIAS_ENDPOINT + modulePath || '' + Constants.idioma + apiPath;
    }
    return Constants.BASE_URL + modulePath || '' + Constants.idioma + apiPath;
  }

  static endPointFilter(
    apiPath: string,
    start: number,
    limit: number,
    search: string
  ): string {
    return `${Constants.BASE_URL}${apiPath}/filter?offset=${start}&limit=${limit}&search=${search}`;
  }

  static endPointFilterMediaFiles(
    apiPath: string,
    start: number,
    limit: number,
    search: string
  ): string {
    return `${Constants.BASE_URL_MEDIAS_ENDPOINT}${apiPath}/filter?offset=${start}&limit=${limit}&search=${search}`;
  }

  static endPointDataTable(
    modulePath: string,
    apiPath: string,
    token: string
  ): string {
    return (
      Constants.BASE_URL +
      modulePath +
      Constants.idioma +
      apiPath +
      '?token=' +
      token
    );
  }

  static endPointDataTableById(
    modulePath: string,
    apiPath: string,
    id: number,
    token: string
  ): string {
    return (
      Constants.BASE_URL +
      modulePath +
      Constants.idioma +
      apiPath +
      '/' +
      id +
      '?token=' +
      token
    );
  }

  static endPointEdit(modulePath: string, id: number): string {
    return `/${modulePath}/${id}`;
  }

  static endPointTime(
    apiPath: string,
    start: number,
    limit: number,
    direction: 'INGRESS' | 'EGRESS',
    deviceId: string,
    startAt: string,
    endAt: string
  ): string {
    return `${environment.accessLog}${apiPath}/time?offset=${start}&limit=${limit}&direction=${direction}&deviceId=${deviceId}&startAt=${startAt}&endAt=${endAt}`;
  }

  static endPointOperators(
    apiPath: string,
    start: number,
    limit: number,
    deviceId: string,
    startAt: string,
    endAt: string
  ): string {
    return `${environment.accessLog}${apiPath}/device/${deviceId}/operator?offset=${start}&limit=${limit}&startAt=${startAt}&endAt=${endAt}`;
  }

  static accessLogsByOperatorId(
    apiPath: string,
    start: number,
    limit: number,
    operatorId: string,
    startAt: string,
    endAt: string
  ): string {
    return `${environment.accessLog}${apiPath}/operator/${operatorId}/devices?offset=${start}&limit=${limit}&startAt=${startAt}&endAt=${endAt}`;
  }

  static endPointDownloadAccessLog(apiPath: string, deviceId: string): string {
    return `${environment.accessLog}${apiPath}/download/${deviceId}`;
  }

  static endPointDownloadAccessLogSidebar(
    apiPath: string,
    participantId: string
  ): string {
    return `${environment.accessLog}${apiPath}/download/operator/${participantId}/devices`;
  }

  static endPointDownloadAccessLogDeviceActivity(
    apiPath: string,
    deviceId: string
  ): string {
    return `${environment.accessLog}${apiPath}/download/device/${deviceId}`;
  }

  static endPointDeviceActivity(
    apiPath: string,
    start: number,
    limit: number,
    deviceId: string,
    startAt: string,
    endAt: string
  ): string {
    return `${environment.accessLog}${apiPath}/device?offset=${start}&deviceId=${deviceId}&limit=${limit}&startAt=${startAt}&endAt=${endAt}`;
  }
}
