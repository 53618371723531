export const environment = {
  production: true,
  hostScheduler: 'https://scheduler.api.robovisorusa.com/',
  host: 'https://ctrl.api.robovisorusa.com/',
  domain: 'https://ctrl.robovisorusa.com',
  domainMedia: 'https://device.robovisorusa.com',
  hostS3: 'https://robovisorusa-repo.s3.sa-east-1.amazonaws.com/',
  wssEndpoint: 'wss://kms.robovisorusa.com/groupcall',
  awsRegion: 'us-east-1',
  awsEndpoint: 'a21hkr3ofk5e2m-ats',
  baseVersion: 'PRD',
  awsTurnCredentials:
    'https://mqqvfhhrcsvowywdmgdf7ecqqe0vzfot.lambda-url.sa-east-1.on.aws/',
  accessLog: 'https://access-log.api.robovisorusa.com/',
  surveyFormEndpoint:
    'https://efz6wwjica.execute-api.us-east-1.amazonaws.com/prod',
  hostMedias: 'https://tj8uvipvn6.execute-api.us-east-1.amazonaws.com/prod/',
  hostTag: 'https://tag.api.robovisorusa.com/api/',
  hostMobileDevices:
    'https://s6qzkdrd9e.execute-api.us-east-1.amazonaws.com/prod/',
  feedbackLambdaUrl:
    'https://bw5auuy5h8.execute-api.us-east-1.amazonaws.com/prod/',
  LLMchat: {
    aws_appsync_graphqlEndpoint:
      'https://b3e3nzxexjggzo2fzpkyvpgbmu.appsync-api.us-east-1.amazonaws.com/graphql',
    apiId: 'rxv7laxmgnf6zfm4ygdmtewpwu'
  },
  hostKnowledgeBase: 'https://uc5k4mz5zi.execute-api.us-east-1.amazonaws.com/prod/'
};
