import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './ui/layout/layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { MobileRedirectGuard } from './core/guards/mobile-redirect/mobile-redirect.guard';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { MENUITEMS } from './shared/menu-items/menu-items';
import { MobileStoreComponent } from './pages/mobile-store/mobile-store.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [MobileRedirectGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
        // canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'dashboard')
            .authGroup
        }
      },
      {
        path: 'company',
        loadChildren: () =>
          import('./pages/company/company.module').then((m) => m.CompanyModule),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'company')
            .authGroup
        }
      },
      {
        path: 'schedule',
        loadChildren: () =>
          import('./pages/schedule/schedule.module').then(
            (m) => m.ScheduleModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'schedule')
            .authGroup
        }
      },
      {
        path: 'operator',
        loadChildren: () =>
          import('./pages/operator/operator.module').then(
            (m) => m.OperatorModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'operator')
            .authGroup
        }
      },
      {
        path: 'device',
        loadChildren: () =>
          import('./pages/device/device.module').then((m) => m.DeviceModule),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'device')
            .authGroup
        }
      },
      {
        path: 'screenshot',
        loadChildren: () =>
          import('./pages/screenshot/screenshot.module').then(
            (m) => m.ScreenshotModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'screenshot')
            .authGroup
        }
      },
      {
        path: 'media',
        loadChildren: () =>
          import('./pages/media/media.module').then((m) => m.MediaModule),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'media')
            .authGroup
        }
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('./pages/setting/setting.module').then((m) => m.SettingModule),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'setting')
            .authGroup
        }
      },
      {
        path: 'security',
        loadChildren: () =>
          import('./pages/security/security.module').then(
            (m) => m.SecurityModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'security')
            .authGroup
        }
      },
      {
        path: 'access-log',
        loadChildren: () =>
          import('./pages/access-log/access-log.module').then(
            (m) => m.AccessLogModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'access-log')
            .authGroup
        }
      },
      {
        path: 'tag-log',
        loadChildren: () =>
          import('./pages/tag-log/tag-log.module').then((m) => m.TagLogModule),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'tag-log')
            .authGroup
        }
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Access Denied'
        }
      },
      {
        path: 'tars',
        loadChildren: () =>
          import('./pages/prompt/prompt.module').then((m) => m.PromptModule),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'tars')
            .authGroup
        }
      },
      {
        path: 'audio-feedback',
        loadChildren: () =>
          import('./pages/audio-feedback/audio-feedback.module').then(
            (m) => m.AudioFeedbackModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: MENUITEMS.find((menuItem) => menuItem.state === 'audio-feedback')
            .authGroup
        }
      }
    ]
  },
  {
    path: 'auth',
    canActivate: [MobileRedirectGuard],
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'mobile-store',
    component: MobileStoreComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
