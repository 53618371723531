import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
  authGroup: string[];
  icon?: string;
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
  authGroup: string[];
}

export const MENUITEMS: MainMenuItems[] = [
  {
    state: 'dashboard',
    short_label: 'DASHBOARD',
    name: 'Dashboard',
    type: 'link',
    icon: 'ti-map-alt red-icon',
    authGroup: ['VIEW_DEVICE']
  },
  {
    state: 'company',
    short_label: 'COMPANIES',
    name: 'Companies',
    type: 'link',
    icon: 'ti-flag-alt green-icon',
    authGroup: ['VIEW_COMPANY']
  },
  {
    state: 'schedule',
    short_label: 'SCHEDULE',
    name: 'Schedules',
    type: 'link',
    icon: 'ti-calendar yellow-icon',
    authGroup: ['VIEW_SCHEDULE']
  },
  {
    state: 'device',
    short_label: 'DEVICES',
    name: 'Devices',
    type: 'link',
    icon: 'ti-signal brown-icon',
    authGroup: ['VIEW_DEVICE']
  },
  {
    state: 'screenshot',
    short_label: 'SCREENSHOTS',
    name: 'Screenshots',
    type: 'link',
    icon: 'ti-camera lime-icon',
    authGroup: ['VIEW_SCREEN_SHOT']
  },
  {
    state: 'media',
    short_label: 'MEDIAS',
    name: 'Media',
    type: 'link',
    icon: 'ti-image purple-icon',
    authGroup: ['VIEW_MEDIA']
  },
  {
    state: 'access-log',
    short_label: 'ACCESS LOGS',
    name: 'Access Logs',
    type: 'link',
    icon: 'ti-time red-icon',
    authGroup: ['VIEW_SELF_ACCESS_LOGS']
  },
  {
    state: 'tag-log',
    short_label: 'TAG LOGS',
    name: 'Tag Logs',
    type: 'link',
    icon: 'ti-credit-card yellow-icon',
    authGroup: ['VIEW_COMPANY_TAG_LOGS']
  },
  {
    state: 'operator',
    short_label: 'OPERATORS',
    name: 'Operators',
    type: 'link',
    icon: 'ti-user blue-icon',
    authGroup: ['VIEW_OPERATOR']
  },
  {
    state: 'setting',
    short_label: 'SETTING',
    name: 'Settings',
    type: 'sub',
    icon: 'fa fa-cogs red-icon',
    authGroup: ['VIEW_OPERATOR_TYPE', 'VIEW_CONTACT_TYPE', 'VIEW_MEDIA_TYPE'],
    children: [
      {
        state: 'contact-type',
        name: 'Contact Type',
        type: 'link',
        authGroup: ['VIEW_CONTACT_TYPE']
      },
      {
        state: 'operator-type',
        name: 'Operator Type',
        type: 'link',
        authGroup: ['VIEW_OPERATOR_TYPE']
      },
      {
        state: 'media-type',
        name: 'Media Type',
        type: 'link',
        authGroup: ['VIEW_MEDIA_TYPE']
      }
    ]
  },
  {
    state: 'security',
    short_label: 'SECURITY',
    name: 'Security',
    type: 'sub',
    icon: 'fa fa-lock green-icon',
    authGroup: ['VIEW_ROLE', 'VIEW_PERMISSION'],
    children: [
      {
        state: 'role',
        name: 'Role',
        type: 'link',
        authGroup: ['VIEW_ROLE']
      },
      {
        state: 'permission',
        name: 'Permission',
        type: 'link',
        authGroup: ['VIEW_PERMISSION']
      }
    ]
  },
  {
    state: 'tars',
    short_label: 'PROMPT',
    name: 'tars',
    type: 'link',
    icon: 'ti-layout-media-overlay dark-icon',
    authGroup: ['VIEW_PROMPT']
  },
  {
    state: 'audio-feedback',
    short_label: 'Audio Feedback',
    name: 'Audio Feedback',
    type: 'link',
    icon: 'ti-layout-media-overlay dark-icon',
    authGroup: ['VIEW_AUDIO_FEEDBACK']
  }
];

@Injectable()
export class MenuItems {
  getAll(): MainMenuItems[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
